<template>
  <div>

   <div v-if="levels.length == 1">
      <level-events-admin-fr-cdf-cva
        :competition_id="d_competition_id"
        :level="levels[0].code"
        :regulation="d_regulation"
        @dataSynchronisationStateChange="levelEventsStateChange"
      >
      </level-events-admin-fr-cdf-cva>

    </div>
    <div v-if="levels.length > 1">
      <collapse-panel v-for="level in levels" :key="level.code"
        :title="level.name"
      >
        <level-events-admin-fr-cdf-cva
          :competition_id="d_competition_id"
          :level="level.code"
          :regulation="d_regulation"
          @dataSynchronisationStateChange="levelEventsStateChange"
          @EventsChange="events_change"
        >
        </level-events-admin-fr-cdf-cva>

      </collapse-panel>
    </div>
  </div>
</template>

<script>
import { RST_COMPETITION, SEASON_COMPETITION_TYPE_SCOPE_EVENT, RST_REF_EVENT, RST_LEVEL } from "@/LocalDB";
//import DataSyncState from '@/components/Utilities/DataSyncState.vue';
//import FormField from '@/components/Panels/FormField.vue';
//import NumberOfRoundsEditor from '@/components/NumberOfRoundsEditor.vue';
import CompetitionsConfigurationHelper from '@/services/helpers/competitionsConfigurationHelper';
import CollapsePanel from '@/components/Panels/CollapsePanel.vue';
import LevelEventsAdminFrCdfCva from "./LevelEventsAdminFrCdfCva.vue";

export default {
  emits: ['EventsChange', 'dataSynchronisationStateChange'],
  model:{
    prop: 'competition_id',
  },
  props:{
    competition_id: { type:String, required:true },
    regulation: { type:String, required:true},
  },
  components: { /*FormField,*/ /*NumberOfRoundsEditor,*/ /*DataSyncState,*/ CollapsePanel , LevelEventsAdminFrCdfCva , },
  data(){
    return {
      d_competition_id: this.competition_id,
      d_regulation: this.regulation,
      //dataSynchronized: null,
      nbPrecision: 0,
      nbBallet: 0,
      nbMix: 0,
      precisionAvailable: false,
      balletAvailable: false,
      mixAvailable: false,
      events:[],
      levels:[],
    }
  },
  computed:{
    nbPrecisionEvents:{
      get() { return this.nbPrecision;},
      set(value){ this.nbPrecision = value; }
    },
    nbBalletEvents:{
      get() { return this.nbBallet;},
      set(value){ this.nbBallet = value; }
    },
    nbMixEvents:{
      get() { return this.nbMix;},
      set(value){ this.nbMix = value; }
    },
    minPrecisionEvents(){ return 0; },
    minBalletEvents() { return 0; },
    minMixEvents() { return 0; },
    maxPrecisionEvents(){ return 4; },
    maxBalletEvents(){ return 4; },
    maxMixEvents(){ return 4; },
  },
  methods:{
    async refresh(){
      await this.$showRefresher();
      var dataSynchronized = await CompetitionsConfigurationHelper.refreshEvents(this.competition_id);
      this.events = RST_REF_EVENT.query().where('competition_id', this.d_competition_id).get();

      var COMPET = RST_COMPETITION.query().where('id', this.d_competition_id).first();
      var EVENTS = SEASON_COMPETITION_TYPE_SCOPE_EVENT.query()
        .where('competition_type', COMPET.competition_type)
        .where('year', COMPET.year)
        .where('scope', COMPET.scope)
        .orderBy('displayOrder')
        .get();

        
      this.levels = RST_LEVEL.query().where("competition_id", this.d_competition_id).get().map(lvl => {
        return {...lvl.LEVEL,
          eventTypes : EVENTS.map(evtType => {
            var level_type_events = this.events.filter(evt => evt.event == evtType.event && evt.level == lvl.level);
            var started = level_type_events.filter(evt => evt.isStarted || evt.isCompleted).length;
            var total = level_type_events.length;
            return {...evtType.EVENT,
              nbMinEvents: started,
              nbEvents: total,
              nbMaxEvents: 3
            };
          }),
        };
      });
      this.$hideRefresher();
      this.$emit('dataSynchronisationStateChange', dataSynchronized);
    },
    async addEvent(eventType, level, displayOrder){

      await this.$showSaver();
      try{
        var result = await CompetitionsConfigurationHelper.createEventAsync(this.d_competition_id, eventType, level, displayOrder);
        this.toastAsyncSaved(result, "Epreuve ajoutée");
        this.$emit('EventsChange');

        await this.refresh();
      } catch (error)
      {
        this.toastSaveFailure("Echec de la création de l'épreuve : " + error);
      }
      this.$hideSaver();
    },
    async removeEvent(eventType, level){

      await this.$showSaver();
      try{
        var result = await CompetitionsConfigurationHelper.deleteEventAsync(this.d_competition_id, eventType, level);
        this.toastAsyncSaved(result, "Epreuve supprimée");
        this.$emit('EventsChange');

         await this.refresh();
      } catch (error)
      {
        this.toastSaveFailure("Echec de la suppression de l'épreuve : " + error);
      }
      this.$hideSaver();
    },
    levelEventsStateChange(state){
      this.$emit('dataSynchronisationStateChange', state);
    },
    events_change(){
      this.$emit('EventsChange');
    },
  },
  async mounted(){

    await this.refresh();
  },
  watch:{
    competition_id: function(newVal){
      this.d_competition_id = newVal;
      this.refresh();
    },
    regulation: function(newVal){
      this.d_regulation = newVal;
      this.refresh();
    }
  }
}
</script>
